import React, {useContext, createContext, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {gql, useMutation, useLazyQuery} from "@apollo/client";
import GetGroupsDetails from "./gql/GetGroupsDetails.graphql";
import GetUsers from "./gql/GetAllUsers.graphql";
import GetUsersInGroup from "./gql/GetUsersInGroup.graphql";
import GetUsersGroupsDetails from "./gql/GetUsersGroupsDetails.graphql";
import GetTrueRegscoutUserCount from "./gql/GetTrueRegscoutUserCount.graphql";
import ToggleUserEnablement from "./gql/ToggleEnablement.graphql";
import axios from "axios";
import {useLogin} from "../components/login/LoginProvider";
import {ALERT} from "../environment";
import {isEmpty} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

export const UsersContext = createContext(null);

export const UsersProvider = ({children}) => {
  const [users, setUsers] = useState();
  const [groups, setGroups] = useState();
  const [usersGroupsDetails, setUsersGroupsDetails] = useState();
  const [groupsDetails, setGroupsDetails] = useState();
  const [trueRegscoutUserCount, setTrueRegscoutUserCount] = useState();
  const {user} = useLogin();

  const getGroups = async () => {
    const response = await axios.get(`${AUTH_ENDPOINT}/manage/groups`, {
      headers: {
        Authorization: `Bearer ${user.IdToken}`,
      },
    });
    setGroups(response.data);
  };

  const [getUsersQuery, getUsersQueryMethods] = useLazyQuery(gql(GetUsers), {
    onCompleted: (data) => {
      setUsers(data.users);
      getGroups();
    },
  });

  useEffect(() => {
    getUsersQuery();
  }, []);

  const [getUsersInGroupQuery, getUsersInGroupQueryMethods] = useLazyQuery(gql(GetUsersInGroup), {
    onCompleted: (data) => setUsers(data.groupUsers),
  });

  const [getGroupsDetailsQuery, getGroupsDetailsQueryMethods] = useLazyQuery(gql(GetGroupsDetails), {
    fetchPolicy: "network-only",
    onCompleted: (data) => setGroupsDetails(data.groupsDetails),
  });

  const [getUsersGroupsDetailsQuery, getUsersGroupsDetailsQueryMethods] = useLazyQuery(gql(GetUsersGroupsDetails), {
    fetchPolicy: "network-only",
    onCompleted: (data) => setUsersGroupsDetails(data.usersGroupsDetails),
  });

    const [getTrueRegscoutUserCountQuery, getTrueRegscoutUserCountQueryMethods] = useLazyQuery(gql(GetTrueRegscoutUserCount), {
    fetchPolicy: "network-only",
    onCompleted: (data) => setTrueRegscoutUserCount(data.getTrueRegscoutUserCount),
  });

  const resendConfirmation = async (username) => {
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/manage/user/revalidate`, {username}, {
        headers: {
          Authorization: `Bearer ${user.IdToken}`,
        },
      });
      if (response.status === 200) ALERT.success("Confirmation email successfully resent.");
    } catch (error) {
      ALERT.error(error?.response?.data?.message ?? "Confirmation email did not send.");
    };
  };

  const [toggleEnablementMutation, toggleEnablementQuery] = useMutation(gql(ToggleUserEnablement), {
    onCompleted: async (data) => {
      const enabled = data.toggleUserEnablement.Enabled;
      ALERT.success(`User successfully ${enabled ? "enabled" : "disabled"}`);
      await getUsersQueryMethods.refetch();
    },
  });

  const toggleUserEnablement = (username) => {
    toggleEnablementMutation({variables: {username}});
  };

  if (isEmpty(users) || isEmpty(groups)) return null;

  return (
    <UsersContext.Provider value={{
      getUsersQuery,
      getUsersQueryMethods,
      getUsersInGroupQuery,
      getUsersInGroupQueryMethods,
      users,
      usersGroupsDetails,
      setUsersGroupsDetails,
      getUsersGroupsDetailsQuery,
      getUsersGroupsDetailsQueryMethods,
      groupsDetails,
      setGroupsDetails,
      getGroupsDetailsQuery,
      getGroupsDetailsQueryMethods,
      getTrueRegscoutUserCountQuery,
      getTrueRegscoutUserCountQueryMethods,
      trueRegscoutUserCount,
      resendConfirmation,
      toggleEnablementQuery,
      toggleUserEnablement,
      groups,
    }}>
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.any,
};

export const useUsers = () => {
  return useContext(UsersContext);
};
