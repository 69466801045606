import React, {createContext, useContext, useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery, gql, useMutation} from "@apollo/client";
import PropTypes from "prop-types";
import GetOneRegulation from "./gql/getOneRegulation.graphql";
import UpdateOneRegulation from "./gql/UpdateOneRegulation.graphql";
import UpsertOneRegulatorLeader from "./gql/UpsertOneRegulatorLeader.graphql";
import UpdateOneNewsItem from "./gql/UpdateOneNewsItem.graphql";
import UpdateOneNotification from "./gql/UpdateOneNotification.graphql";
import UpdateOneTemplate from "./gql/NotificationTemplates/UpdateOneNotificationTemplate.graphql";
import DeleteOneTemplate from "./gql/NotificationTemplates/DeleteOneNotificationTemplate.graphql";
import CreateOneRegulation from "./gql/CreateOneRegulation.graphql";
import CreateOneNewsItem from "./gql/CreateOneNewsItem.graphql";
import CreateOneNotification from "./gql/CreateOneNotification.graphql";
import CreateOneTemplate from "./gql/NotificationTemplates/CreateOneNotificationTemplate.graphql";
import DeleteOneRegulatorLeader from "./gql/DeleteOneRegulatorLeader.graphql";
import SyncTagsForRegulation from "./gql/SyncTagsForRegulation.graphql";
import SyncGroupsForRegulation from "./gql/SyncGroupsForRegulation.graphql";
import GetTagTypes from "./gql/GetTagTypes.graphql";
import GetLegalTypes from "./gql/GetLegalTypes.graphql";
import dayjs from "dayjs";
import camelcase from "camelcase";
import {ALERT} from "../environment";
import {useNavigate} from "react-router-dom";
import {getStateByName, getCountryByName} from "../shared/geography";
import {each, isEmpty, map} from "underscore";

const notificationLabels = {
  REGULATOR: "Regulator",
  CONSUMER: "Consumer",
  CREDIT_RATING_AGENCY: "Consumer Reporting Agencies",
  SUBSTITUTE_NOTICE: "Substitute Notice",
};

const RegulationContext = createContext(undefined);

const getGeographiesAsRegulationTags = (data) => {
  const countryTags = map(data.regulationCountries, (countryName) => {
    return {
      tag: {
        type: {name: "Geography"},
        value: {
          geographyType: "Country",
          country: getCountryByName(countryName),
        },
      },
    };
  });
  const stateTags = map(data.regulationStates, (stateName) => {
    const state = getStateByName("US", stateName) ?? getStateByName("CA", stateName);

    return {
      tag: {
        type: {name: "Geography"},
        value: {
          geographyType: "State",
          state,
        },
      },
    };
  });

  return [...countryTags, ...stateTags];
};

const convertRegulationTagsToTags = (regulationTags) => {
  return map(regulationTags, (regulationTag) => {
    return {
      type: {
        name: regulationTag.tag.type.name,
      },
      value: JSON.stringify(regulationTag.tag.value),
    };
  });
};

const RegulationProvider = ({children}) => {
  const [regulation, setRegulation] = useState();
  const [tagTypes, setTagTypes] = useState([]);
  const [regulationLegalTypes, setLegalTypes] = useState([]);
  const [notificationTemplate, setNotificationTemplate] = useState([]);
  const [whichNotificationTemplateOpen, setWhichNotificationTemplateOpen] = useState("");
  const {id} = useParams();
  const navigate = useNavigate();

  const [updateRegulation, updateRegulationMethods] = useMutation(gql(UpdateOneRegulation), {
    onCompleted: () => {
      ALERT.success("Regulation updated!");
    },
    onError: (error) => {
      ALERT.error("Error updating regulation");
      console.error(error);
    },
  });
  const [createRegulation, createRegulationMethods] = useMutation(gql(CreateOneRegulation), {
    onCompleted: (data) => {
      ALERT.success("Regulation created!");
      navigate(`/regulations/edit/${data.createOneRegulation.id}`);
    },
    onError: (error) => {
      ALERT.error("Error creating regulation");
      console.error(error);
    },
  });

  const [upsertRegulatorLeader, upsertRegulatorLeaderMethods] = useMutation(
      gql(UpsertOneRegulatorLeader),
      {
        awaitRefetchQueries: true,
        refetchQueries: [{query: gql(GetOneRegulation), variables: {id: parseInt(id, 10)}}],
        onCompleted: () => {
          ALERT.success("Regulator leader updated!");
        },
        onError: (error) => {
          ALERT.error("Error updating regulator leader");
          console.error(error);
        },
      },
  );
  const [deleteOneRegulatorLeader, deleteOneRegulatorLeaderMethods] = useMutation(
      gql(DeleteOneRegulatorLeader),
      {
        awaitRefetchQueries: true,
        refetchQueries: [{query: gql(GetOneRegulation), variables: {id: parseInt(id, 10)}}],
        onCompleted: () => {
          ALERT.success("Regulator leader deleted!");
        },
        onError: (error) => {
          ALERT.error("Error deleting regulator leader");
          console.error(error);
        },
      },
  );

  const [createNewsItem, createNewsItemMethods] = useMutation(gql(CreateOneNewsItem), {
    onError: (error) => {
      ALERT.error("Error creating news item");
      console.error(error);
    },
  });
  const [updateNewsItem, updateNewsItemMethods] = useMutation(gql(UpdateOneNewsItem), {
    onError: (error) => {
      ALERT.error("Error updating news item");
      console.error(error);
    },
  });
  const [updateNotification, updateNotificationMethods] = useMutation(gql(UpdateOneNotification), {
    onError: (error) => {
      ALERT.error("Error updating notification");
      console.error(error);
    },
  });
  const [createNotification, createNotificationMethods] = useMutation(gql(CreateOneNotification), {
    onError: (error) => {
      ALERT.error("Error creating notification");
      console.error(error);
    },
  });

  const [syncTagsForRegulation, syncTagsForRegulationMethods] = useMutation(
      gql(SyncTagsForRegulation),
      {
        onError: (error) => {
          ALERT.error("Error syncing tags for regulation");
          console.error(error);
        },
      },
  );

  const [syncGroupsForRegulation, syncGroupsForRegulationMethods] = useMutation(
      gql(SyncGroupsForRegulation),
      {
        onError: (error) => {
          ALERT.error("Error syncing groups for regulation");
          console.error(error);
        },
      },
  );

  const getRegulationQuery = useQuery(gql(GetOneRegulation), {
    variables: {id: parseInt(id, 10)},
    onCompleted: (data) => {
      setNotificationTemplate(
          map(data.regulation.notifications, (notification) => notification.notification),
      );
      setRegulation(data.regulation);
    },
    onError: (error) => {
      ALERT.error("Error getting one regulation");
      console.error(error);
    },
    skip: !id,
  });

  const [createTemplate, createTemplateMethods] = useMutation(gql(CreateOneTemplate), {
    awaitRefetchQueries: true,
    refetchQueries: [{query: gql(GetOneRegulation), variables: {id: parseInt(id, 10)}}],
    onError: (error) => {
      ALERT.error("Error creating one template");
      console.error(error);
    },
  });

  const [updateTemplate, updateTemplateMethods] = useMutation(gql(UpdateOneTemplate), {
    awaitRefetchQueries: true,
    refetchQueries: [{query: gql(GetOneRegulation), variables: {id: parseInt(id, 10)}}],
    onError: (error) => {
      ALERT.error("Error updating one template");
      console.error(error);
    },
  });

  const [deleteTemplate, deleteTemplateMethods] = useMutation(gql(DeleteOneTemplate), {
    awaitRefetchQueries: true,
    refetchQueries: [{query: gql(GetOneRegulation), variables: {id: parseInt(id, 10)}}],
    onError: (error) => {
      ALERT.error("Error deleting one template");
      console.error(error);
    },
  });

  const getTagTypesQuery = useQuery(gql(GetTagTypes), {
    onCompleted: (data) => setTagTypes(data.tagTypes),
    onError: (error) => {
      ALERT.error("Error getting tag types");
      console.error(error);
    },
  });

  const getLegalTypeQuery = useQuery(gql(GetLegalTypes), {
    onCompleted: (data) => {
      setLegalTypes(map(data.__type.enumValues, (enumValue) => enumValue.name));
    },
    onError: (error) => {
      ALERT.error("Error getting legal types");
      console.error(error);
    },
  });

  const loading =
    updateRegulationMethods.loading ||
    upsertRegulatorLeaderMethods.loading ||
    updateNewsItemMethods.loading ||
    updateNotificationMethods.loading ||
    getRegulationQuery.loading ||
    createNewsItemMethods.loading ||
    createNotificationMethods.loading ||
    createRegulationMethods.loading ||
    deleteOneRegulatorLeaderMethods.loading ||
    getTagTypesQuery.loading ||
    syncTagsForRegulationMethods.loading ||
    getLegalTypeQuery.loading ||
    syncGroupsForRegulationMethods.loading ||
    createTemplateMethods.loading ||
    updateTemplateMethods.loading ||
    deleteTemplateMethods.loading;

  const create = async (data) => {
    const {
      data: {createOneRegulation: createdRegulation},
    } = await createRegulation({
      variables: {
        isDraft: data.isDraft,
        regulator: data.regulator,
        description: data.description,
        obligationRegex: data.obligationRegex || null,
        exceptions: data.exceptions,
        source: data.source,
        penalties: data.penalties,
        piiDefinition: data.piiDefinition,
        otherRequirements: data.otherRequirements,
        legislationPassedAt: dayjs(data.legislationPassedAt),
        effectiveAt: dayjs(data.effectiveAt),
        legalType: data.legalType,
        typeName: data.regulationType,
      },
    });

    const geographyTags = getGeographiesAsRegulationTags(data);
    const parsedTags = convertRegulationTagsToTags([...data.regulationTags, ...geographyTags]);

    await syncTagsForRegulation({
      variables: {
        regulation: {id: createdRegulation.id},
        tags: parsedTags,
      },
    });

    await syncGroupsForRegulation({
      variables: {
        regulation: {id: createdRegulation.id},
        groups: data.groups,
      },
    });

    for (const notificationType of Object.keys(notificationLabels)) {
      const notificationContent = data[`${camelcase(notificationType)}Notification`];
      const notificationConditions = data[`${camelcase(notificationType)}Conditions`];

      await createNotification({
        variables: {
          regulationId: createdRegulation.id,
          content: notificationContent ?? "",
          conditions: notificationConditions ?? "",
          type: notificationType,
        },
      });
    }
  };

  const update = async (data) => {
    each(data?.newsItems, async (news) => {
      const newsItem = news.newsItem;
      if (news?.isDirty) {
        await updateNewsItem({
          variables: {
            id: newsItem.id,
            isDraft: newsItem.isDraft,
            publisher: newsItem.publisher,
            publishedAt: dayjs(newsItem.publishedAt).toISOString(),
            source: newsItem.source,
            title: newsItem.title,
          },
        });
      }
      if (news?.isNew) {
        if (!(isEmpty(newsItem.publisher) && isEmpty(newsItem.source) && isEmpty(newsItem.title))) {
          await createNewsItem({
            variables: {
              isDraft: newsItem.isDraft,
              publisher: newsItem.publisher,
              publishedAt: dayjs(newsItem.publishedAt).toISOString(),
              source: newsItem.source,
              title: newsItem.title,
              regulationId: data.id,
            },
          });
        }
      }
    });

    const geographyTags = getGeographiesAsRegulationTags(data);
    const parsedTags = convertRegulationTagsToTags([
      ...data.regulationTags.filter((regulationTag) => regulationTag.tag.type.name !== "Geography"),
      ...geographyTags,
    ]);
    await syncTagsForRegulation({
      variables: {
        regulation: {id: regulation.id},
        tags: parsedTags,
      },
    });

    await syncGroupsForRegulation({
      variables: {
        regulation: {id: regulation.id},
        groups: data.groups,
      },
    });

    for (const regulationNotification of regulation?.notifications) {
      const notification = regulationNotification.notification;

      const notificationContent = data[`${camelcase(notification.type)}Notification`];
      const notificationConditions = data[`${camelcase(notification.type)}Conditions`];

      await updateNotification({
        variables: {
          id: notification.id,
          content: {set: notificationContent ?? ""},
          conditions: {set: notificationConditions ?? ""},
        },
      });
    }

    updateRegulation({
      variables: {
        id: regulation.id,
        isDraft: {set: data.isDraft},
        regulator: {set: data.regulator},
        description: {set: data.description},
        obligationRegex: {set: data.obligationRegex || null},
        exceptions: {set: data.exceptions},
        source: {set: data.source},
        penalties: {set: data.penalties},
        piiDefinition: {set: data.piiDefinition},
        otherRequirements: {set: data.otherRequirements},
        legislationPassedAt: {set: dayjs(data.legislationPassedAt)},
        effectiveAt: {set: dayjs(data.effectiveAt)},
        legalType: {set: data.legalType},
        typeName: data.regulationType,
      },
    });
  };

  const updateRegulatorLeader = (upsertData) => {
    upsertRegulatorLeader({variables: {regulationId: regulation.id, upsertData}});
  };

  const deleteRegulatorLeader = () => {
    deleteOneRegulatorLeader({variables: {regulationId: regulation.id}});
  };

  const providerContent = {
    regulation,
    tagTypes,
    regulationLegalTypes,
    notificationLabels,
    notificationTemplate,
    whichNotificationTemplateOpen,
    setWhichNotificationTemplateOpen,
    create,
    update,
    loading,
    createTemplate,
    createTemplateMethods,
    updateRegulatorLeader,
    deleteRegulatorLeader,
    updateTemplate,
    updateTemplateMethods,
    deleteTemplate,
    deleteTemplateMethods,
  };

  return (
    <RegulationContext.Provider value={providerContent}>{children}</RegulationContext.Provider>
  );
};

RegulationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useRegulation = () => {
  return useContext(RegulationContext);
};

export {useRegulation, RegulationProvider};
