import React, {useState} from "react";
import {Box, IconButton, ListItemButton, ListItemText, List, Menu, Divider} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {useTheme} from "../shared/ConsolidatedThemeProvider";
import {useLogin} from "./login/LoginProvider";
import clearRegulationCache from "../regulations/gql/clearRegulationCache.graphql";
import {gql, useMutation} from "@apollo/client";
import {ALERT} from "../environment";

const MainMenu = () => {
  const {toggleActiveTheme} = useTheme();
  const {isUserFullAdmin, logout} = useLogin();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const navigate = useNavigate();

  const [clearRegulationCacheMutation] = useMutation(gql(clearRegulationCache), {
    onCompleted: () => ALERT.success("Successfully cleared cache"),
    onError: () => ALERT.error("Couldn't clear cache"),
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isUserAFullAdmin = isUserFullAdmin();

  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuOutlinedIcon />
      </IconButton>
      <Menu
        id="main-menu"
        aria-labelledby="main-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box>
          <List disablePadding>
            {!isUserAFullAdmin ? null : (
              <>
                <ListItemButton
                  id="users-button"
                  aria-controls="user-button"
                  aria-label="users-button"
                  onClick={() => {
                    navigate("/users");
                    handleClose();
                  }}
                >
                  <ListItemText primary="Manage Users" />
                </ListItemButton>
                <ListItemButton
                  id="regulations-button"
                  aria-controls="regulations-button"
                  aria-label="regulations-button"
                  onClick={() => {
                    navigate("/");
                    handleClose();
                  }}
                >
                  <ListItemText primary="Manage Regulations" />
                </ListItemButton>
                <ListItemButton
                  id="regulation-groups-button"
                  aria-controls="regulation-groups-button"
                  aria-label="regulation-groups-button"
                  onClick={() => {
                    navigate("/groups");
                    handleClose();
                  }}
                >
                  <ListItemText primary="Manage Regulation Groups" />
                </ListItemButton>
                <ListItemButton
                  id="clear-regulation-cache-button"
                  aria-controls="clear-regulation-cache-button"
                  aria-label="clear-regulation-cache-button"
                  onClick={async () => {
                    await clearRegulationCacheMutation();
                    handleClose();
                  }}
                >
                  <ListItemText primary="Clear Regulation Cache" />
                </ListItemButton>
                <Divider component="li" />
                <ListItemButton
                  id="settings-button"
                  aria-controls="settings-button"
                  aria-label="settings-button"
                  onClick={() => {
                    navigate("/settings");
                    handleClose();
                  }}
                >
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </>
            )}
            <ListItemButton
              id="toggle-theme-button"
              aria-controls="toggle-theme-button"
              aria-label="toggle-theme-button"
              onClick={() => toggleActiveTheme()}
            >
              <ListItemText primary="Toggle Theme" />
            </ListItemButton>
            <ListItemButton
              id="logout-button"
              aria-controls="logout-button"
              aria-label="logout-button"
              onClick={() => logout()}
            >
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Box>
      </Menu>
    </>
  );
};

export default MainMenu;
